import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomError } from '@orthofi/ngx-error-handler';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IOnboardingTaskSummary } from '@shared/models/IOnboardingTaskSummary';
import { OnboardingTask, IOnboardingTask } from '@shared/models/IOnboardingTasks';
import { OnboardingTaskStatusId } from '@shared/models/OnboardingTaskStatusId';
import { OnboardingTaskTypeId } from '@shared/models/OnboardingTaskTypeId';
import { IAttachment } from '@shared/models/IAttachment';
import {
    IAccount, ICreateConnectAcctResponse, IUpdateAccount, IUpdateBankAccount,
    IBankAccount, IUpdateStripeBankAccount, IPracticeStripeAccount
} from 'src/app/models/onboarding.models';
import { IEnvironmentConfig } from '@webpack/extra-webpack-types';

const fakeTask = {
    OnboardingTaskType: 'Credential' as OnboardingTaskTypeId,
    OnboardingTaskId: '99999',
    Name: 'Test Task',
    OnboardingTaskStatus: 'Not Submitted' as OnboardingTaskStatusId,
    Description: 'Ut at commodo ante. Etiam dignissim id mauris quis pellentesque. Pellentesque pellentesque laoreet enim, quis fringilla nulla elementum non.',
    PracticeId: '55555555',
    OnboardingConditionalQuestion: {
        Question: '',
        CancelText: 'No',
        ConfirmText: 'Yes'
    },
    Directions: '<ul><li>Commodo placerat libero.</li><li>Posuere rhoncus nunc.</li></ul>',
    OrderId: 500,
    ExampleAttachments: [],
    OnboardingTaskTemplateId: '999999999',
    Note: { NoteText: 'Orci varius natoque penatibus et magnis dis parturient montes' },
    uploads: []
};

/** Credentials to be used when interacting with the OrthoFi Keymaster API */
const httpOptions = { withCredentials: true };
const pleaseTryAgain = 'Please try again in a few moments and if the issue persists, contact support.';

/** An API based data service for managing user registration and invites. */
@Injectable()
export class OnboardingService {
    /** @ignore */
    private _apiRoot = 'https://gatekeeper.orthofi.com';

    /** @ignore */
    constructor(
        @Inject('ANGULAR_ENV') private _env: IEnvironmentConfig,
        private _http: HttpClient
    ) {
        this._apiRoot = `${_env.gatekeeperUrl}/api/v1`;
    }

    /**
   * Retrieve a list of Tasks the list of tasks (for the practice?).
   *
   * @practiceID The ID of the practice.
   */
    getTaskList(PracticeID: number): Observable<Array<IOnboardingTaskSummary>> {
        return this._http
            .get<Array<IOnboardingTaskSummary>>(`${this._apiRoot}/onboarding/tasks?practiceId=${PracticeID}`, httpOptions)
            .pipe(
                catchError(
                    this.handleError('Sorry, we\'re unable to load the list of tasks to complete.', false)
                ));
    }

    /**
   * Use a task's ID to retrieve details about it.
   *
   * @practiceID The ID of the practice.
   * @taskID The ID of the task to return the details of.
   */
    getTaskByID(taskID: string): Observable<OnboardingTask> {
        return this._http
            .get<IOnboardingTask>(`${this._apiRoot}/onboarding/tasks/${taskID}`, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we're unable to load the information about how to complete this task. ${pleaseTryAgain}`, false)
                ));
    }

    /**
   * Use an invitation token to submit a user's details and register the account.
   *
   * @param taskID The ID of the task to save the information for.
   * @param body An object containing details about the user to register and their invitation token.
   */
    putTask(taskID: string, body: OnboardingTask): Observable<OnboardingTask> {
        const url = `${this._apiRoot}/onboarding/tasks/${taskID}`;
        return this._http
            .put<IOnboardingTask>(url, body, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to save the task information. ${pleaseTryAgain}`)
                ));
    }

    postTaskAttachment(taskID: string, file: File): Observable<IAttachment> {
        const url = `${this._apiRoot}/onboarding/tasks/${taskID}/attachment`;
        const data: FormData = new FormData();
        data.append('file', file, file.name);
        return this._http
            .post<IAttachment>(url, data, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to upload the file. ${pleaseTryAgain}`)
                ));
    }

    deleteTaskAttachment(taskID: string, attachmentId: number): Observable<boolean> {
        const url = `${this._apiRoot}/onboarding/tasks/${taskID}/attachment/${attachmentId}`;
        return this._http
            .delete<boolean>(url, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to delete the file. ${pleaseTryAgain}`)
                ));
    }

    /**
   * Initiate a call to onboard a Connect account
   */
    postConnectAccount(requestBody): Observable<ICreateConnectAcctResponse> {
        const url = `${this._apiRoot}/onboarding/connect`;
        return this._http
            .post<ICreateConnectAcctResponse>(url, requestBody, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to create the account. ${pleaseTryAgain}`)
                ));
    }

    postContinueOnboarding(requestBody): Observable<ICreateConnectAcctResponse> {
        const url = `${this._apiRoot}/onboarding/connect/onboarding-link`;
        return this._http
            .post<ICreateConnectAcctResponse>(url, requestBody, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to open the continue onboarding link. ${pleaseTryAgain}`)
                ));
    }

    getAccounts(practiceId: number): Observable<IAccount> {
        const url = `${this._apiRoot}/onboarding/connect/${practiceId}`;
        return this._http.get<IAccount>(url, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to retrieve account list. ${pleaseTryAgain}`)
                ));
    }

    updateConnectedAccount(account: IUpdateAccount): Observable<any> {
        const url = `${this._apiRoot}/onboarding/connect/update-account`;
        return this._http.patch<any>(url, account, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to update the account. ${pleaseTryAgain}`)
                ));
    }

    deactivateConnectAccount(practiceStripeAccount: IPracticeStripeAccount): Observable<any> {
        const url = `${this._apiRoot}/onboarding/connect/deactivate`;
        return this._http.post<IPracticeStripeAccount>(url, practiceStripeAccount, httpOptions).pipe(
            catchError(
                this.handleError('Sorry, this account cannot be removed.')
            ));
    }

    updateConnectedBankAccount(requestBody: IUpdateStripeBankAccount): Observable<any> {
        const url = `${this._apiRoot}/onboarding/connect/attach-bank-details`;
        return this._http.post<any>(url, requestBody, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to update the account. ${pleaseTryAgain}`)
                ));
    }

    postBankAccount(requestBody: IBankAccount): Observable<any> {
        const url = `${this._apiRoot}/practice/bank-details`;
        return this._http
            .post<any>(url, requestBody, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to create the account. ${pleaseTryAgain}`)
                ));
    }

    getBankAccounts(practiceId: number): Observable<IAccount> {
        const url = `${this._apiRoot}/practice/bank-details/${practiceId}`;
        return this._http.get<IAccount>(url, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to retrieve account list. ${pleaseTryAgain}`)
                ));
    }

    updateBankAccount(account: IUpdateBankAccount): Observable<any> {
        const url = `${this._apiRoot}/practice/bank-details`;
        return this._http.patch<any>(url, account, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to update the account. ${pleaseTryAgain}`)
                ));
    }

    getBankName(routingNumber: string): Observable<any> {
        const url = `${this._apiRoot}/practice/bank-details/verify/${routingNumber}`;
        return this._http.get<any>(url, httpOptions)
            .pipe(
                catchError(
                    this.handleError(`Sorry, we encountered an error when attempting to retrieve account list. ${pleaseTryAgain}`)
                ));
    }

    handleError(message: string, notify = true) {
        return (error: any) => {
            if (error.status === 400) {
                message =  error.error.Message;
                return throwError(new CustomError(
                    message
                ));
            }

            if (error.status !== 401) {

                return throwError(new CustomError(
                    message,
                    {
                        showSupportId: true,
                        errorObject: error,
                        ...(notify && {
                            notificationPriority: 'info',
                            notificationOptions: { icon: { name: 'error' } }
                        }),
                    }
                ));
            }
            return throwError(error);
        };
    }

}
