// Angular
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
// Theming
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Services
import { OnboardingService } from './http/onboarding.service';
// Other
import { GoogleAnalyticsLibModule } from '@orthofi/ngx-google-tag-manager';
import { LoaderModule } from '@orthofi/ngx-loader';
import { NotofierModule } from '@orthofi/ngx-notofier';
import { SharedModule } from '../shared/shared.module';
import {
    FoundationLayoutModule,
    FoundationHeaderModule,
    FoundationFooterModule,
    FoundationNotFoundModule,
    FoundationAuthenticatedUserAPIModule
} from '@orthofi/ngx-foundation';

/**
 * `CoreModule` is the home of several singletons, including HTTP services. It should only ever be imported in the `AppModule`
 * and never in any feature modules, otherwise multiple instances of the singletons within would be created. Single use
 * components such as the header can also go in the `CoreModule`.
 */
@NgModule({
    imports: [
    // Angular
        BrowserModule,
        HttpClientModule,
        RouterModule,
        SharedModule,
        BrowserAnimationsModule,
        // Other
        LoaderModule.forRoot(),
        NotofierModule.forRoot(),
        GoogleAnalyticsLibModule.forRoot(),
        FoundationLayoutModule.forRoot({ backgroundClassList: ['layout--is-columns', 'bg-fill-gray'] }),
        FoundationHeaderModule,
        FoundationFooterModule,
        FoundationLayoutModule,
        FoundationNotFoundModule,
        FoundationAuthenticatedUserAPIModule.forRoot()
    ],
    providers: [
    // HTTP: Authentication and global API services
        OnboardingService,
    ],
    exports: [
        LoaderModule
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard. Ensure this module is only ever created once.
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }
}
