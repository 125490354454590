import { Component, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsLibService } from '@orthofi/ngx-google-tag-manager';
import { LoaderBusService } from '@orthofi/ngx-loader';
import { FoundationLayoutService } from '@orthofi/ngx-foundation';
import { IEnvironmentConfig } from '@webpack/extra-webpack-types';

/** The main starting component, containing the top most wrapper and loader.  */
@Component({
    selector: 'of-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
@Injectable()
export class AppComponent {
    /** ignore */
    constructor(
        @Inject('ANGULAR_ENV') private _env: IEnvironmentConfig,
        private _router: Router,
        private _loader: LoaderBusService,
        private _tagManager: GoogleAnalyticsLibService,
        private _layout: FoundationLayoutService
    ) {
        _layout.userMenuItems = [
            { name: 'Account', url: `${_env.portalUrl}/Account/MyAccount`},
            { name: 'Log Out', url: `${_env.authenticationApi}/api/account/logout?redirect_url=${encodeURIComponent(window.location.origin)}` }
        ];
        this.init();
    }

    init(): void {
    // Initialize the practice select in a loading state.
    // Show the loader while navigating.
        this._loader.forceOnNavigate(this._router);
        // Have Google Analytics follow route changes.
        this._tagManager.emitRouterChanges(this._router);
    }

}
