import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SuccessSnackbarComponent } from './success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        MatIconModule
    ],
    declarations: [
        SuccessSnackbarComponent,
        ErrorSnackbarComponent
    ],
    exports: [
        SuccessSnackbarComponent,
        ErrorSnackbarComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SnackbarModule { }
