import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OfiAuthenticatedUserAPIService } from '@orthofi/ngx-foundation';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _authService: OfiAuthenticatedUserAPIService) { }

    canActivate(): Observable<boolean> {
        console.log('guard');
        return this._authService.resolveUser().pipe(
            filter((userResponse) => !!userResponse),
            map((userResponse) => {
                console.log('guard success');
                return !!userResponse;
            }),
        );
    }
}
