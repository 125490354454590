
// Error Handling
import { NgModule } from '@angular/core';
import { ErrorHandlerModule } from '@orthofi/ngx-error-handler';

// Other
import { SentryLoggingModule } from '@orthofi/ngx-sentry';
import { LoaderModule } from '@orthofi/ngx-loader';
// import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Modules
import { CoreModule } from '@core/core.module';
import { SharedModule } from './shared/shared.module';
import { Title } from '@angular/platform-browser';
import APP_CONFIG from '@webpack/extra-webpack-types';
import { SnackbarModule } from '../app/snackbars/snackbars.module';

/**
 * The main application module containing the core module, shared module, and any feature modules.
 * initialized, the main application component will be created.
 */
@NgModule( {
    declarations: [
        AppComponent
    ],
    imports: [
    // Modules
        CoreModule,
        SharedModule,
        // Error Handling
        ErrorHandlerModule.forRoot(),
        SentryLoggingModule.forRoot(),
        // Loader
        LoaderModule.forRoot(),

        // App Routing must be imported last
        AppRoutingModule,
        SnackbarModule
    ],
    providers: [
        Title,
        { provide: 'ANGULAR_ENV', useValue: APP_CONFIG },
        // Error Handling:
        { provide: 'ERROR_HANDLER_OPTIONS', useValue: APP_CONFIG.errorHandlerOptions },
        { provide: 'ERROR_LOGGER_OPTIONS', useValue: APP_CONFIG.errorLoggerOptions },
        // Other
        { provide: 'AUTHENTICATION_API',    useValue: APP_CONFIG.authenticationApi },
        { provide: 'PORTAL_URL',            useValue: APP_CONFIG.portalUrl },
    ],
    bootstrap: [AppComponent]
} )
export class AppModule { }
