// Core
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components
import { FoundationNotFoundComponent, FoundationLayoutComponent } from '@orthofi/ngx-foundation';
import { AuthGuard } from './core/guards/authentication.guard';
// import { LayoutComponent } from './core/components/layout/layout.component';

/** The application's top level routing table. */
const routes: Routes = [
    {
        path: '',
        component: FoundationLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'tasks',
                pathMatch: 'full'
            },
            {
                path: 'tasks',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pages/tasks/tasks.module').then(m => m.TasksModule)
            },
            { path: '**', component: FoundationNotFoundComponent },
        ],
    }
];

/** The top level application router. */
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
